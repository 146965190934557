export const sysdata = [
  {
    id: "1",
    name: "数据驾驶舱",
    icon: "08.png",
    list: [
      {
        id: "1",
        name: "开发中",
        isShow: false,
      },
    ],
  },
  {
    id: "2",
    name: "国信底座",
    icon: "07.png",
    list: [
      {
        id: "2-1",
        name: "国信物联管理中心",
        isShow: true,
      },
      {
        id: "2-2",
        name: "国信云",
        isShow: true,
      },
      {
        id: "2-3",
        name: "国信云预警",
        isShow: true,
      },
    ],
  },
  {
    id: "3",
    name: "监测预警",
    icon: "06.png",
    list: [
      {
        id: "3-1",
        name: "水库大坝安全监测",
        isShow: true,
      },
      {
        id: "3-2",
        name: "山洪监测",
        isShow: true,
      },
      {
        id: "3-3",
        name: "地质灾害",
        isShow: true,
      },

      {
        id: "3-4",
        name: "河长制",
        isShow: true,
      },
      {
        id: "3-5",
        name: "综合防灾",
        isShow:true
      },
      {
        id: "3-6",
        name: "城市内涝",
        isShow: true,
      },
      {
        id: "3-7",
        name: "视频监测",
        isShow: true,
      },

      // {
      //   id: "11",
      //   name: "其他",
      //   syslist: [
      //     {
      //       id: "39",
      //       name: "国信华源物联网管理系统",
      //       type: "其他",
      //       url: "http://101.200.163.224:9090/GxhyAgreement/page/login.jsp",
      //       username: "xiaopl",
      //       password: "123456",
      //       appCode: "",
      //       isoutside: false,
      //     },
      //     // {
      //     //   id: "6",
      //     //   name: "北京市水库安全监测预警系统外挂",
      //     //   type: "其他",
      //     //   url: "http://47.107.44.149:8009/#/waig",
      //     //   username: "",
      //     //   password: "",
      //     //   appCode: "",
      //     //   isoutside: false,
      //     // },
      //     // {
      //     //   id: "7",
      //     //   name: "广西水库安全监测预警系统外挂",
      //     //   type: "其他",
      //     //   url: "http://47.107.44.149:8009/#/waiggx",
      //     //   username: "",
      //     //   password: "",
      //     //   appCode: "",
      //     //   isoutside: false,
      //     // },
      //     {
      //       id: "40",
      //       name: "江西省九江市瑞昌市水库水文自动测报系统运行维护管理系统",
      //       type: "其他",
      //       url: "http://skcbyw.jxwrd.gov.cn:8080/oms/index.html#/oneMap",
      //       username: "瑞昌市李四",
      //       password: "Jxsl@1234",
      //       appCode: "",
      //       isoutside: false,
      //     },
      //     {
      //       id: "41",
      //       name: "地灾气象预警",
      //       type: "其他",
      //       url: "http://218.6.244.186:12001/SCDZZH/login",
      //       username: "admin",
      //       password: "admin123",
      //       appCode: "",
      //       isoutside: false,
      //     },
      //   ],
      // },
    ],
  },
  {
    id: "4",
    name: "物业化",
    icon: "09.png",
    list: [
      {
        id: "4-1",
        name: "水库物业化",
        isShow:true
      },{
        id: "4-2",
        name: "水库标准化",
        isShow:true
      },
      {
        id: "4-3",
        name: "水闸物业化",
        isShow:true
      },
      
      // {
      //   id: "6",
      //   name: "运行维护",
      //   syslist: [
      //     {
      //       id: "27",
      //       name: "综合运维管理系统",
      //       type: "运行维护",
      //       url: "http://mend.gxhyiot.com/",
      //       username: "",
      //       password: "",
      //       appCode: "",
      //       isoutside: false,
      //     },
      //   ],
      // },
    ],
  },
  {
    id: "5",
    name: "数字孪生",
    icon: "05.png",
    list: [
      {
        id: "1",
        name: "开发中",
        syslist: [],
      },
    ],
  },
  {
    id: "6",
    name: "可视化大屏",
    icon: "04.png",
    list: [
      {
        id: "1",
        name: "开发中",
        syslist: [],
      },
    ],
  },
  {
    id: "7",
    name: "知识库",
    icon: "03.png",
    list: [
      {
        id: "1",
        name: "开发中",
        syslist: [],
      },
    ],
  },
  {
    id: "8",
    name: "应用支持",
    icon: "02.png",
    list: [
      {
        id: "1",
        name: "开发中",
        syslist: [],
      },
    ],
  },
  {
    id: "9",
    name: "产品中心",
    icon: "01.png",
    list: [
      {
        id: "1",
        name: "开发中",
        syslist: [],
      },
    ],
  },
];
export function syslistfun(dataType) {
  let list = [];
  switch (dataType) {
    case "2-1":
      list = [
        {
          id: "11",
          name: "国信物联管理中心测试",
          type: "国信物联",
          url: "http://glzxweb.gxhy.cc:88",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "国信物联管理中心",
          type: "国信物联",
          url: "http://pm.gxhyiot.com/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "2-2":
      list = [
        {
          id: "12",
          name: "国信云测试",
          type: "国信物联",
          url: "http://gxyun.gxhy.cc:88/#/login",
          username: "gxyun",
          password: "Gx123456@",
          appCode: "",
          isoutside: false,
        },
        {
          id: "12",
          name: "国信云",
          type: "国信物联",
          url: "http://cloud.gxhyiot.com/#/login",
          username: "gxyun",
          password: "Gx123456@",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "2-3":
      list = [
        {
          id: "11",
          name: "国信云预警系统测试",
          type: "国信物联",
          url: "http://www.gxhy.cc/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "国信云预警系统",
          type: "国信物联",
          url: "https://cloud-alarm.gxhypro.com/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "3-1":
      list = [
        {
          id: "13",
          name: "水库安全监测预警系统测试",
          type: "水库大坝安全",
          url: "http://skdbweb.gxhy.cc:88/#/login",
          username: "test",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "13",
          name: "河南灵宝平台",
          type: "水库大坝安全",
          url: "http://120.77.219.179:9010/gxhy",
          username: "test",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
        // {
        //   id: "14",
        //   name: "水库下游监测预警系统",
        //   type: "水库大坝安全",
        //   url: "http://222.216.6.173:6871/gxhy/login/login.html;jsessionid=F86749E3F988D18BB0BCD0744DE7D51C",
        //   username: "gxhyt",
        //   password: "Gx@wasd10!",
        //   appCode: "",
        //   isoutside: false,
        // },
        {
          id: "15",
          name: "公司水库下游监测预警信息系统",
          type: "水库大坝安全",
          url: "http://120.77.219.179:9141/gxhy/login/login.html",
          username: "guizhousheng",
          password: "gz12345678",
          appCode: "",
          isoutside: false,
        },
        {
          id: "16",
          name: "桂平中小型水库水情监测",
          type: "水库大坝安全",
          url: "http://120.77.219.179:9151/gxhy/login/login.html",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "162",
          name: "水库安全监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "2270",
          name: "青秀区小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620112542125.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_qxq",
          username: "gx_qingxq",
          password: "Gxshengj@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "541",
          name: "昌平演示系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=ys",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "1446",
          name: "水库大坝安全监测预警体验系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=pub",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        // {
        //   id: "2271",
        //   name: "江南区小型水库监测预警系统",
        //   type: "水库大坝安全",
        //   logo: "2022/06/20//20220620112653503.jpg",
        //   url: "http://www.gxhypro.com/#/login?sys=gx_jnq",
        //   username: "",
        //   password: "",
        //   appCode: "",
        //   isoutside: false,
        // },
        // {
        //   id: "2272",
        //   name: "西乡塘区小型水库监测预警系统",
        //   type: "水库大坝安全",
        //   logo: "2022/06/20//20220620112832090.jpg",
        //   url: "http://www.gxhypro.com/#/login?sys=gx_xxtq",
        //   username: "",
        //   password: "",
        //   appCode: "",
        //   isoutside: false,
        // },
        // {
        //   id: "1756",
        //   name: "钦南区小型水库监测预警系统",
        //   type: "水库大坝安全",
        //   logo: "2022/05/31//20220531110726417.jpg",
        //   url: "http://www.gxhypro.com/#/login?sys=qn",
        //   username: "",
        //   password: "",
        //   appCode: "",
        //   isoutside: false,
        // },
        // {
        //   id: "2274",
        //   name: "邕宁区小型水库监测预警系统",
        //   type: "水库大坝安全",
        //   logo: "2022/06/20//20220620131957170.jpg",
        //   url: "http://www.gxhypro.com/#/login?sys=gx_ynq",
        //   username: "",
        //   password: "",
        //   appCode: "",
        //   isoutside: false,
        // },
        {
          id: "1627",
          name: "临沂市小型水库雨水工情自动测报系统",
          type: "水库大坝安全",
          logo: "2022/06/14//20220614214618116.png",
          url: "http://www.gxhypro.com/#/login?sys=sdly",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        // {
        //   id: "2273",
        //   name: "良庆区小型水库监测预警系统",
        //   type: "水库大坝安全",
        //   logo: "2022/06/20//20220620112918616.jpg",
        //   url: "http://www.gxhypro.com/#/login?sys=gx_lqq",
        //   username: "",
        //   password: "",
        //   appCode: "",
        //   isoutside: false,
        // },
        // {
        //   id: "2275",
        //   name: "武鸣区小型水库监测预警系统",
        //   type: "水库大坝安全",
        //   logo: "2022/06/20//20220620132110155.jpg",
        //   url: "http://www.gxhypro.com/#/login?sys=gx_wmq",
        //   username: "",
        //   password: "",
        //   appCode: "",
        //   isoutside: false,
        // },
        {
          id: "2276",
          name: "隆安县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620132218452.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_lax",
          username: "gx_longax",
          password: "Gxlongax@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "169",
          name: "广西小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=gx",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "1307",
          name: "河南省淤地坝监测预警系统",
          type: "水库大坝安全",
          logo: "2022/03/09//20220309110308329.jpg",
          url: "http://www.gxhypro.com/#/login?sys=hn",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "2268",
          name: "南宁市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620112248320.jpg",
          url: "http://www.gxhypro.com/#/login?sys=nann",
          username: "gx_nann",
          password: "Gxnann@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "2277",
          name: "马山县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620132313303.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_msx",
          username: "gx_masx",
          password: "Gxmasx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "2278",
          name: "上林县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620132354133.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_shlx",
          username: "gx_shanglx",
          password: "Gxshangl@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "2280",
          name: "横县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620132615724.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_hx",
          username: "gx_hengx",
          password: "Gxhengx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "2279",
          name: "宾阳县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620132516151.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_byx",
          username: "gx_binyx",
          password: "Gxbinyx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3612",
          name: "北海市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620132944690.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_beihs",
          username: "gx_beihs",
          password: "Gxbeihs@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3613",
          name: "海城区小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133044643.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_haicq",
          username: "gx_haicq",
          password: "Gxhaicq@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3615",
          name: "铁山港区小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133151505.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_tiesgq",
          username: "gx_tiesgq",
          password: "Gxtiesgq@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3614",
          name: "银海区小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133121734.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_yinhq",
          username: "gx_yinhq",
          password: "Gxbinyx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3616",
          name: "合浦县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133357269.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_hepx",
          username: "gx_hepx",
          password: "Gxhepx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3617",
          name: "钦州市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133436682.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_qinzs",
          username: "gx_qinzs",
          password: "Gxqinzs@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3618",
          name: "钦北区小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133528706.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_yinbq",
          username: "gx_yinbq",
          password: "Gxyinbq@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3620",
          name: "浦北县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133751300.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_pubx",
          username: "gx_pubx",
          password: "Gxpubx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5783",
          name: "崇左市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134459325.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_chzs",
          username: "gx_chongzs",
          password: "Gxchzs@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5784",
          name: "江州区小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134538365.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_jiangzq",
          username: "gx_jiangzq",
          password: "Gxjzq@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5785",
          name: "扶绥县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134622881.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_fusx",
          username: "gx_fusx",
          password: "Gxfusx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5786",
          name: "宁明县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134701204.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_ningmx",
          username: "gx_ningmx",
          password: "Gxningmx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5787",
          name: "龙州县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134732308.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_longzx",
          username: "gx_longzx",
          password: "Gxlongzx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5788",
          name: "大新县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134805319.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_daxx",
          username: "gx_daxx",
          password: "Gxdaxx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5789",
          name: "天等县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134837957.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_tdx",
          username: "gx_tiandx",
          password: "Gxtiandx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "5790",
          name: "凭祥市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620134924869.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_pingxx",
          username: "gx_pingxx",
          password: "Gxpingxx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10756",
          name: "广元市苍溪县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/07/28//20220728212019535.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gy",
          username: "guangyu",
          password: "Guanyuan@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10757",
          name: "合山市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/07/28//20220728215420216.jpg",
          url: "http://www.gxhypro.com/#/login?sys=hss",
          username: "gx_hess",
          password: "Gxhess@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "3619",
          name: "灵山县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620133656214.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_lingsx",
          username: "gx_lingsx",
          password: "Lingsx@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10759",
          name: "岳阳市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=yys",
          username: "yueyang_admin",
          password: "Yuey@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "2269",
          name: "兴宁区小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "2022/06/20//20220620112430181.jpg",
          url: "http://www.gxhypro.com/#/login?sys=gx_xnq",
          username: "gx_xingnq",
          password: "Gxxingn@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10758",
          name: "衡阳市小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=hys",
          username: "hys_admin",
          password: "Hyx@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10760",
          name: "牡丹江市水务局亮子河水库",
          type: "水库大坝安全",
          logo: "2022/08/16//20220816085603648.jpg",
          url: "http://www.gxhypro.com/#/login?sys=mdj",
          username: "mudanjiang",
          password: "Mdj@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10761",
          name: "灵川县小型水库安全监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=lcx",
          username: "lingch_admin",
          password: "Lingch@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "恭城县小型水库安全监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=glgcx",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "湘乡市小型水库雨水情和大坝安全监测系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=xx",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "宜州区小型水库安全监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=gx_yz",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "鄂尔多斯市小型水库雨水情和大坝安全监测系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=eer",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },

        {
          id: "10762",
          name: "江油市水库安全监测系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=jy",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "山西长治襄垣水库安全监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=xh",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "宜川县小型水库雨水情和大坝安全监测系统",
          type: "水库大坝安全",
          logo: "",
          url: "https://std.gxhypro.com/#/login?sys=yc",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "河南水库安全监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=hns",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "金秀县小型水库雨水情和大坝安全监测系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=jx",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "宜川县水库大坝安全监测系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=sx_yc",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "麻阳县小型水库标准化管护系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=myx",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "炎陵县小型水库标准化管护系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=ylx",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "百旺水库安全监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=bwsk",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "太湖县小型水库雨水情和大坝安全监测系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=th",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "安化县小型水库标准化管护系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=anhuax",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "堤防智能巡检系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=dif",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10762",
          name: "广元市剑阁县小型水库监测预警系统",
          type: "水库大坝安全",
          logo: "",
          url: "http://www.gxhypro.com/#/login?sys=jg",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },

      ];
      break;
    case "3-2":
      list = [
        {
          id: "20",
          name: "2019年山洪灾害设备预警管理系统",
          type: "山洪监测",
          url: "http://120.77.219.179:10001/gxhy/module/login/login.jsp",
          username: "admin",
          password: "admin123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "21",
          name: "江西省山洪灾害预警设施设备监控维护管理系统",
          type: "山洪监测",
          url: "http://111.75.205.72:9090/#/login",
          username: "省防办值班室4",
          password: "省防办值班室41234567",
          appCode: "",
          isoutside: false,
        },
        {
          id: "22",
          name: "国信华源预警防汛管理平台",
          type: "山洪监测",
          url: "http://101.200.163.224:7070/gxhymanager/login.jsp",
          username: "admin",
          password: "gxhy123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "23",
          name: "河南基层防汛监测预警设备信息管理平台",
          type: "山洪监测",
          url: "http://222.143.34.48:9010/gxhy/module/login/login.jsp;jsessionid=A048A44DEAB582DE4977761BF029D185",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "24",
          name: "无线预警广播管理系统",
          type: "山洪监测",
          url: "http://222.216.6.173:8060/website/",
          username: "sheng",
          password: "mengxiang1987",
          appCode: "",
          isoutside: false,
        },
        {
          id: "25",
          name: "山洪灾害设备预警管理系统",
          type: "山洪监测",
          url: "http://123.57.157.139:8091/gxhy/main.jsp",
          username: "admin",
          password: "admin123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "26",
          name: "国信华源设备预警监测系统",
          type: "山洪监测",
          url: "http://120.77.219.179:8011/gxhy/",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "3-3":
      list = [
        {
          id: "18",
          name: "东川地质灾害监测预警系统",
          type: "地质灾害",
          url: "http://116.52.166.246:9925/#/login",
          username: "dcqu",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "19",
          name: "通江县地质灾害监测预警系统",
          type: "地质灾害",
          url: "http://118.123.152.27:7362/#/login",
          username: "tongjx",
          password: "tjx123456",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "3-4":
      list = [
        {
          id: "28",
          name: "瑞金河长制",
          type: "河长制",
          url: "http://111.75.246.209:9090",
          username: "18172705775",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "29",
          name: "威远河长制",
          type: "河长制",
          url: "http://www.slhzt.com:8088/gxhy",
          username: "gongkui",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "30",
          name: "四川省河长制湖长制信息化平台",
          type: "河长制",
          url: "http://118.178.44.182:8081/schz_/login.html",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "31",
          name: "石城河长制",
          type: "河长制",
          url: "http://www.slhzt.com/",
          username: "cmc",
          password: "gxhy123456",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "3-5":
      list = [
        {
          id: "38",
          name: "国信物联综合防灾监测预警",
          type: "综合防灾",
          url: "http://yunnpc.gxhyiot.com/",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "3-6":
      list = [
        {
          id: "32",
          name: "安庆排涝系统",
          type: "内涝",
          url: "http://112.26.113.100:8081/gxhy",
          username: "admin",
          password: "gxhy123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "33",
          name: "九江市湖泊监测预警平台",
          type: "内涝",
          url: "http://182.106.200.20:9014/#/login",
          username: "jiujiang",
          password: "jj123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "34",
          name: "城市内务预测信息预警系统",
          type: "内涝",
          url: "http://101.200.163.224:6060/nljcmanager/login.jsp",
          username: "admin",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "3-7":
      list = [
        {
          id: "35",
          name: "公司视频系统",
          type: "视频",
          url: "http://120.77.219.179:10018/gxhy/login/login.html",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
        {
          id: "36",
          name: "视频监控系统",
          type: "视频",
          url: "http://120.77.219.179:9251/gxhy/login/login.html",
          username: "hanchengshi",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "37",
          name: "黎川视频系统",
          type: "视频",
          url: "http://120.77.219.179:10033/gxhy/login/login.html",
          username: "",
          password: "",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "4-1":
      list = [
        {
          id: "107255",
          name: "望城区小型水库标准化管护系统",
          type: "水库物业化",
          logo: "2022/07/28//20220728211131052.jpg",
          url: "http://www.gxhypro.com/#/login?sys=wc",
          username: "wangch",
          password: "Wangch@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "17",
          name: "浏阳市小型水库标准化管护系统",
          type: "水库物业化",
          logo: "2022/07/28//20220728211131052.jpg",
          url: "http://47.112.159.173:18082/#/login",
          username: "liuys",
          password: "123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "10755",
          name: "桃江县小型水库标准化管护系统",
          type: "水库物业化",
          logo: "2022/07/28//20220728211131052.jpg",
          url: "http://www.gxhypro.com/#/login?sys=tj",
          username: "wangch",
          password: "Wangch@123",
          appCode: "",
          isoutside: false,
        },
        {
          id: "107155",
          name: "湘阴县小型水库标准化管护系统",
          type: "水库物业化",
          logo: "2022/07/28//20220728211131052.jpg",
          url: "http://www.gxhypro.com/#/login?sys=xy",
          username: "wangch",
          password: "Wangch@123",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "4-2":
      list = [
        {
          id: "11",
          name: "小型水库标准化管理系统测试",
          type: "水库标准化",
          url: "http://skdbstdweb.gxhy.cc:88/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "小型水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "益阳市小型水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login?sys=yy",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "桃江县小型水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login?sys=tjx",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "湘乡市小型水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login?sys=xx",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },

        {
          id: "11",
          name: "资阳市小型水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login?sys=zys",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "湘阴县小型水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login?sys=xyx",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "官亭水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login?sys=gt",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "麻阳县小型水库标准化管理系统",
          type: "水库标准化",
          url: "https://std.gxhypro.com/#/login?sys=my",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
    case "4-3":
      list = [
        {
          id: "11",
          name: "水闸工程标准化管理系统测试",
          type: "水闸物业化",
          url: "http://sluice.gxhy.cc:88/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
        {
          id: "11",
          name: "水闸工程标准化管理系统",
          type: "水闸物业化",
          url: "http://sluice.gxhypro.com/#/login",
          username: "caots",
          password: "Caots@123456",
          appCode: "",
          isoutside: false,
        },
      ];
      break;
  
    }
  return list;
}
