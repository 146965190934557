<template>
  <div class="container">
    <el-container>
      <el-header class="main_header"
        ><div>
          <img src="@/assets/images/xtlogo.png" alt="" />
          <div>
            <h3>国信华源综合管理中心</h3>
          </div>
        </div>

        <div class="mt-4" v-if="showList">
          <el-input
            @change="changeInput"
            v-model="input"
            placeholder="系统名称检索"
            
          >
          </el-input>
        </div></el-header
      >
      <el-container class="main_content" v-if="!showList">
        <el-aside width="200px">
          <!-- 左侧fixed导航区域 -->
          <div class="left">
            <div
              v-for="item in sysdata"
              :key="item.id"
              class="left_item"
              :class="item.id == selectedId ? 'selected' : ''"
              @click="leftItemClick(item.id)"
            >
              <img :src="require('@/assets/images/' + item.icon)" alt="" />
              {{ item.name }}
            </div>
          </div>
        </el-aside>
        <el-container>
          <el-main id="right">
            <!-- 右侧内容区域 -->
            <div class="right">
              <div v-for="item in rightList" :key="item.id" class="right_item">
                <h3>{{ item.name }}</h3>
                <ul>
                  <li v-for="it in item.list" @click="gotoSyslist(it)">
                    <img src="@/assets/images/xtlogo.png" alt="" />
                    <p>{{ it.name }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
      <el-container class="main_content" v-if="showList">
        <el-aside width="200px">
          <!-- 左侧fixed导航区域 -->
          <div class="left">
            <div
              v-for="item in sysdata"
              :key="item.id"
              class="left_item"
              :class="item.id == selectedId ? 'selected' : ''"
              @click="leftItemClickNew(item.id)"
            >
              <img :src="require('@/assets/images/' + item.icon)" alt="" />
              {{ item.name }}
            </div>
          </div>
        </el-aside>
      
          <el-main class="main_main">
            <el-button icon="el-icon-arrow-left" type="primary" @click="showList = !showList">返回</el-button>
              <el-row :gutter="20">
                <el-col :span="12" v-for="item in sysdataList" :key="item.id">
                  <div class="main_item">
                    <div><img src="@/assets/images/n_icon.png" alt="" /></div>
                    <div>
                      <p>{{ item.name }}</p>
                      <p>{{item.type}}</p>
                      <p>
                        <!-- <span>账号：{{item.username || '暂无'}}</span><span>密码：{{item.password || '暂无'}}</span> -->
                      </p>
                      <el-button type="primary" @click="gotoUrl(item.url)" round size="medium"
                        >立即进入</el-button
                      >
                    </div>
                    <div>
                      <div @click="onCopy(item.url)">
                        <i class="el-icon-link"></i> 分享链接
                      </div>
                      <!-- <div v-clipboard:copy="item.url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError">
                        <img src="@/assets/images/link.png" alt="" /> 分享链接
                      </div> -->
                      <div @click="dialogVisible = true" v-if="item.appCode != ''">
                        <img
                          src="@/assets/images/QRcode.png"
                          alt=""
                        />APP下载二维码
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-main>
      
      </el-container>
    </el-container>
  </div>
</template>

<script>
import scrollUtil from "@/utils/scrollUtil.js";
import { sysdata,syslistfun } from "@/utils/sysdata.js";

export default {
  data() {
    return {
      leftList: [],
      rightList: [],
      selectedId: 1,
      offsetTopArr: [],
      sysdata,
      input: "",
      showList:false,
      sysdataList:[],
      sysdataListNo:[]
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);

    this.getOffsetTopArr();
  },
  destroyed() {
    // 移除监听器
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    changeInput(){
      let key =this.input;
      console.log(key)
      key =  key.replace( /\s*/g, "" ); //去除搜索内容中的空格
      const reg  = new RegExp( key, "ig" ); //匹配规则-i：忽略大小写，g：全局匹配
      this.sysdataList = this.sysdataListNo.filter( (item) => item.name.match( reg ) !=null ); //进行筛选，将筛选后的数据放入新的数组中
    },
    gotoUrl(url){
      window.open(url, "_blank");
    },
    onCopy(url){
      var input = document.createElement("input"); // 创建input对象
      input.value = url; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message({
        message: "链接复制成功",
        type: "success",
      });
    },
    gotoSyslist(item){
      if(item.isShow){
        this.showList = true
      this.sysdataList = syslistfun(item.id)
      this.sysdataListNo = syslistfun(item.id)
      }else{
        this.$message({
        message: "暂无内容",
        type: "warning",
      });
      }
      
    },
    //获取右侧所有item的offsetTop
    getOffsetTopArr() {
      this.$nextTick(() => {
        let rightListItems = document.querySelectorAll(".right .right_item");
        if (rightListItems && rightListItems.length > 0) {
          rightListItems.forEach((item) => {
            this.offsetTopArr.push(item.offsetTop);
          });
          console.log(this.offsetTopArr);
        }
      });
    },
    //页面滚动监听
    onScroll() {
      // 获取当前文档流的 scrollTop
      var div = document.getElementById("right");
      const scrollTop =
        (document.documentElement.scrollTop || document.body.scrollTop)+ 100;
        // div.scrollTop;
      console.log("scrollTop=" + scrollTop);
      for (let i = 0; i < this.offsetTopArr.length; i++) {
        if (scrollTop >= this.offsetTopArr[i]) {
          this.selectedId = this.rightList[i].id;
        }
        // todo 优化 => 改成小于等于，加break 少循环
      }
    },
    leftItemClickNew(id){
      this.showList = !this.showList
      this.selectedId = id
    },
    //左侧item点击事件
    leftItemClick(id) {
      this.selectedId = id;

      let index = 0;
      for (let i = 0; i < this.rightList.length; i++) {
        if (this.rightList[i].id == id) {
          index = i;
          break;
        }
      }

      const targetOffsetTop = this.offsetTopArr[index];
      scrollUtil.scrollTo(targetOffsetTop -100);
    },
    //初始化数据源
    initData() {
      this.rightList = this.sysdata;
    },
  },
  watch:{

    showList: {
      handler: function (newV, oldV) {
        console.log(newV)
        if(!newV){
          window.addEventListener("scroll", this.onScroll);
        }else{
           // 移除监听器
    window.removeEventListener("scroll", this.onScroll);
        }
      },
      deep: true,
      immediate: true,
  },
  }
};
</script>

<style lang="less" scoped>
.container {
  background: url("../assets/images/bg.png");
  background-attachment: fixed;
  background-size: cover;
  .left {
    position: fixed;
    width: 200px;
    overflow: auto;
    float: left;
    color: #fff;

    .left_item {
      width: 100%;
      height: 84px;
      text-align: center;
      line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-top: 10px;
      img {
        width: 25px;
        margin-right: 10px;

        // height: 30px;
      }
    }

    .selected {
      background: url("../assets/images/装饰.png") 100%/100%;
      justify-content: center;
      font-weight: bold;
      color: #fff;
    }
  }
  .el-main {
  }
  .right {
    margin-left: 50px;
    width: 90%;
    overflow: auto;

    .right_item {
      width: 100%;
      height: 200px;
      // text-align: center;
      // line-height: 200px;
      font-size: 24px;
      // border-bottom: 1px solid #ccc;
      h3 {
        color: #fff;
        background: url("../assets/images/top.png") 100%/100% no-repeat;
        width: 243px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-weight: normal;
      }
      ul {
        display: flex;
        list-style: none;
        margin-left: 55px;
        li {
          background: url("../assets/images/xtbj.png") 100%/100% no-repeat;
          width: 180px;
          height: 127px;
          margin-right: 10px;
          color: rgba(255,255,255,0.7);
          font-size: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          cursor: pointer;
          img {
            width: 50px;
          }
        }
      }
    }
  }
}
::v-deep{
  .el-input__inner{
    background: rgba(255,255,255,0.2);
    border: none;
    color: #fff;
  }
}

::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 10px;
}
.el-header {
  position: fixed;
  top: 0;
}
.main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background: transparent;
  height: 100px;
  z-index: 999;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
  }
  img{
    margin-right: 10px;
  }
}
.top_search {
  display: flex;
  justify-content: space-between;
  // background: #fff;
  align-items: center;
  .top_left {
    span {
      padding: 5px;
      border-radius: 2px;
      color: #fff;
    }
    span:first-of-type {
      background: #4795ff;
      margin-right: 10px;
    }
    span:last-of-type {
      background: #ff8847;
    }
  }
}
.mt-4 {

}
.main_content {
  margin-top: 60px;
  
}
.el-aside {
  padding: 10px 20px;
}
.main_main {
  margin-top:10px;
  margin-left: 50px;
  color: #fff;
  // height: 100%;
  height: calc(100vh - 70px);
  .main_item {
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 80px 10px 10px;
    > div:first-of-type {
      flex: 1;
      img {
        width: 3.7rem;
        height: 3.7rem;
      }
    }
    > div:nth-of-type(2) {
      flex: 6;
      p:first-of-type {
        font-weight: bold;
      }
      p:nth-of-type(2) {
        background: #eaeef6;
        display: inline-block;
        font-size: 14px;
        padding: 0px 7px;
        border-radius: 10px;
        color: #666;
        margin-top: 5px;
      }
      p:last-of-type {
        font-size: 12px;
        margin: 10px 0px;
        span {
          margin-right: 10px;
        }
      }
    }
    > div:last-of-type {
      flex: 3;
      font-size: 14px;
      div {
        display: flex;
        align-items: center;
        justify-content: end;
        cursor: pointer;
        img {
          width: 20px;
        }
      }
      div:last-of-type {
        margin-top: 10px;
      }
    }
  }
}

</style>
