<template>
  <div id="app">
    
    <Project msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Project from './views/Project.vue'

export default {
  name: 'App',
  components: {
    Project
  }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0px !important;
}
</style>
